
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useSidClient } from "@/plugins/auth";
import { useStore } from "@/store";
import { useReCaptcha } from "vue-recaptcha-v3";
import Portal from "@/views/Portal/Portal.vue";

export default defineComponent({
    components: {
        Portal,
    },
    setup() {
        const sidClient = useSidClient();
        const store = useStore();
        const route = useRoute();
        const { instance, recaptchaLoaded } = useReCaptcha() as any;
        // reCAPTCHAバッジ非表示
        recaptchaLoaded().then(() => instance.value.hideBadge());

        const initializedRef = ref(false);

        // ライブラリ初期化待ち合わせ
        sidClient.waitInitialized().then(async () => {
            // サイトリスト取得
            await store.dispatch("fetchSiteLinkage");
            initializedRef.value = true;
        });

        onMounted(() => {
            if (navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("iPod") > 0) {
                // iphone
                const body = document.getElementsByTagName("body")[0];
                body.classList.add("iphone");
            } else if (navigator.userAgent.indexOf("iPad") > 0) {
                // iOS13以前のiPad
                const body = document.getElementsByTagName("body")[0];
                body.classList.add("ipad");
            } else if (navigator.userAgent.indexOf("Safari") > 0 && navigator.userAgent.indexOf("Chrome") == -1 && typeof document.ontouchstart !== "undefined") {
                // iOS13以降のiPad
                const body = document.getElementsByTagName("body")[0];
                body.classList.add("ipad");
            }
        });

        watchEffect(() => {
            // タイトル設定
            document.title = (route.meta.title) ? route.meta.title + " | SHUEISHA ID" : "SHUEISHA ID";
        });

        return {
            initializedRef,
        };
    },
});
