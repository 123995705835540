
import { computed, defineComponent, ref } from "vue";
import SideMenu from "@/components/Portal/SideMenu.vue";
import { loginStatus } from "@/modules/loginStatus";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "Header",
    components: {
        SideMenu
    },
    setup() {
        const route = useRoute();
        const store = useStore();

        const isActiveRef = ref(false);
        const nickName = computed(() => (typeof(store.state.userInfo.commonAttrInfo.nickName) === "string") ? store.state.userInfo.commonAttrInfo.nickName.replaceAll("&lt;","<").replaceAll("&gt;",">") : store.state.userInfo.commonAttrInfo.nickName);
        const isDispSideMenu = computed(() => route.name !== "QuitService");

        const changeIsActive = (isActive: boolean) => {
            isActiveRef.value = isActive;
        };

        return {
            store,
            isActiveRef,
            nickName,
            isDispSideMenu,
            changeIsActive,
            loginStatus
        };
    },
});
