
import { defineComponent, ref, watch } from "vue";
import Header from "@/components/Portal/Header.vue";
import Footer from "@/components/Portal/Footer.vue";
import { useSidClient } from "@/plugins/auth";
import { useStore } from "@/store";
import { loginStatus } from "@/modules/loginStatus";
import { useRouter } from "vue-router";

export default defineComponent({
    components: {
        Header,
        Footer,
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();

        const initializedRef = ref(false);

        sidClient.isAuthenticated().then(isAuth => {
            if (isAuth) {
                store.dispatch("fetchUserInfo")
                    .catch(e => {
                        // ログイン状態で基本属性情報取得に失敗した場合、基本属性情報登録画面へ遷移してしまうため、ログイン画面へ遷移させる
                        if (e.message === "Failed to get information.") {
                            sidClient.signOut();
                            router.push({ name: "Login" });
                        }
                    })
                    .finally(() => {
                        initializedRef.value = true;
                    });
            } else {
                initializedRef.value = true;
            }
        });

        watch(loginStatus, () => {
            if (!loginStatus.value) {
                store.dispatch("resetUserInfo");
            }
        });

        return {
            initializedRef
        };
    },
});
